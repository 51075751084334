import {
  useRouteError,
  isRouteErrorResponse,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

export default function ErrorReport() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <p>
          To help us make Virtual In-Processing better be please file a bug
          report,{' '}
          <a
            className="text-blue-700 underline hover:text-blue-300 active:text-blue-400 visited:text-blue-700"
            href="https://travisspark.atlassian.net/servicedesk/customer/portal/4"
            target="_blank"
            rel="noreferrer">
            here
          </a>
          .
        </p>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data.message}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div className="p-5 min-w-[600px] max-w-full">
        <div className="h-[300px] mb-32">
          <p>
            An unexpected error has occurred please use the form below to
            provide more information.
          </p>
          <p>
            To help us make Virtual In-Processing better be please file a bug
            report,{' '}
            <a
              className="text-blue-700 underline hover:text-blue-300 active:text-blue-400 visited:text-blue-700"
              href="https://travisspark.atlassian.net/servicedesk/customer/portal/4"
              target="_blank"
              rel="noreferrer">
              here
            </a>
            .
          </p>
        </div>
        {process.env.NODE_ENV === 'development' && (
          <>
            <h1 className="font-bold mb-1">
              This error will be automatically sent with your report
            </h1>
            <div className="bg-gray-300 p-2">
              <h1 className="font-bold">Error</h1>
              <p>{error.message}</p>
              <p className="font-bold">The stack trace is:</p>
              <pre className="text-wrap">{error.stack}</pre>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <>
        <h1>Unknown Error Encountered</h1>
        <p>
          To help us make Virtual In-Processing better be please file a bug
          report,{' '}
          <a
            className="text-blue-700 underline hover:text-blue-300 active:text-blue-400 visited:text-blue-700"
            href="https://travisspark.atlassian.net/servicedesk/customer/portal/4"
            target="_blank"
            rel="noreferrer">
            here
          </a>
          .
        </p>
      </>
    );
  }
}
